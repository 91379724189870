import _ from "lodash";
import { SUPPLIER_ROLES } from "src/constants/permissions";
import { formatMoneyUnlimitedDecimals } from "src/utility/utilityFunctions";

import { variantId } from "@features/items";
import { displayDate } from "@features/reports";
import { PurchaseOrderVariant } from "@models/PurchaseOrderVariant";
import { CsvSchema } from "@utils/csv";

const insertIf = (condition, ...items): CsvSchema<PurchaseOrderVariant> =>
  condition ? items : [];

function purchaseOrderHistorySchema(role) {
  const roleIs = (...roles) => roles.flat().includes(role);
  const isSupplier = roleIs(SUPPLIER_ROLES);
  const actualCost = (pov) =>
    Number(isSupplier ? pov.actualCost : pov.galloCost);
  const schema: CsvSchema<PurchaseOrderVariant> = [
    {
      id: "purchaseOrder.id",
      label: "Purchase Order #",
    },
    { id: "poExternalSapId", label: "SAP PO #" },
    { id: "poSupplierReference", label: "Supplier Ref #" },
    ...insertIf(roleIs("super", SUPPLIER_ROLES), {
      id: "poInvoiceNumber",
      label: "Invoice #",
    }),
    {
      id: "_variantId",
      label: "Seq #",
      f: (_, pov) => variantId(pov.variant),
    },
    ...insertIf(!isSupplier, {
      id: "supplierName",
      label: "Supplier",
    }),
    { id: "brandNames", label: "Brand", f: (v) => v.join(", ") },
    { id: "itemExternalWorkfrontId", label: "Project #" },
    { id: "itemTypeDescription", label: "Item Type" },
    { id: "itemComment", label: "Item Description" },
    { id: "qty", label: "Qty" },
    ...insertIf(!isSupplier, {
      id: "itemEstimatedCost",
      label: "Est. Cost/Unit",
      f: (v) => formatMoneyUnlimitedDecimals(v),
    }),
    {
      id: "_actCost",
      label: "Act. Cost/Unit",
      f: (_, pov) => formatMoneyUnlimitedDecimals(actualCost(pov)),
    },
    {
      id: "_totalCost",
      label: "Total cost",
      f: (_, pov) => formatMoneyUnlimitedDecimals(pov.qty * actualCost(pov)),
    },
    {
      id: "poStatus",
      label: "Status",
      f: (v, pov) =>
        isSupplier && pov.poStatus === "submitted" ? "New" : _.startCase(v),
    },
    {
      id: "poSubmittedAt",
      label: "Submitted Date",
      f: displayDate,
    },
    {
      id: "poInMarketDate",
      label: "In Market Date",
      f: (inMarketDate, pov) =>
        displayDate(
          isSupplier && !pov.poIsDirectShip
            ? pov.poDeliverToWarehouseDate
            : inMarketDate
        ),
    },
    ...insertIf(
      roleIs("super", "supplier"),
      { id: "poShipmentCount", label: "Shipment Count" },
      { id: "poKeyAccountTape", label: "Shipper Tape" }
    ),
    ...insertIf(roleIs("super", "select-purchaser"), {
      id: "poSentToFlowAt",
      label: "Sent to Flow Date",
      f: displayDate,
    }),
    { id: "poPurchaserName", label: "Purchased By" },
    {
      id: "poIsDirectShip",
      label: "Allocated",
      f: (v) => (v ? "Direct Ship" : "CDC"),
    },
  ];

  return schema;
}

export default purchaseOrderHistorySchema;
